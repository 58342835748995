import styled from "styled-components";
import media from "styled-media-query";

export const CTAButton = styled.button`
  margin: 0;
  background-color: #111;
  border-color: #111;
  border-radius: 16px;
  transition: all 0.5s;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: #272727;
    border-color: #272727;
  }

  &:active {
    background-color: #111;
    border-color: #111;
  }
`;

export const ImageContainer = styled.div`
  max-height: 22rem;
  flex: 0 auto;
  margin-right: 6.5%;
  margin-bottom: 3rem;
  transition: all 0.5s;

  img {
    max-height: 27rem;
  }

  ${media.greaterThan("small")`
    margin-right: 6.5%;
      margin-bottom: 0;
  `}
`;

export const Container = styled.a<{
  backgroundColor: string;
  textColor: string;
}>`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: auto;
  margin-bottom: 6rem;
  justify-content: space-between;
  align-items: center;
  transition: height 0.2s, width 0.2s;
  background-color: ${({ backgroundColor }) => backgroundColor}};
  color: ${({ textColor }) => textColor}};
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    color: ${({ textColor }) => textColor}};
  }

  ${media.greaterThan("medium")`
    border-radius: 16px;
    flex-direction: row;
    align-items: flex-end;
    flex-direction: row;
    align-items: flex-end;

    &:hover {
      transform: scale(1.025);
      ${ImageContainer} {
        transform: scale(1.05);
      }
      ${CTAButton} {
        transform: scale(1.05);
      }
    }
  `}
`;

export const CaseInfo = styled.div`
  display: flex;
  margin: 2rem 0;
  margin-left: 6.5%;
  flex-direction: column;
  align-items: flex-start;

  h4 {
    margin-bottom: 0.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 900;
  }

  p {
    margin-bottom: 2rem;
  }

  ${media.greaterThan("medium")`
    width: 30%;
    flex: 0 auto;
    margin-bottom: 3rem;

    p {
      margin-bottom: 3rem;
    }
  `}
`;
