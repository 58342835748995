import React from "react";
import CaseStudySection from "../CaseStudySection/CaseStudySection";

import * as Style from "./Gallery.style";

const Gallery: React.FC<{
  backgroundColor?: string;
  images: string[];
}> = (props) => {
  return (
    <CaseStudySection backgroundColor={props.backgroundColor || "#eee"}>
      <Style.Inner>
        {props.images.map((source, index) => (
          <div key={`gallery-img-${index}`}>
            <img src={source} alt="case" />
          </div>
        ))}
      </Style.Inner>
    </CaseStudySection>
  );
};

export default Gallery;
