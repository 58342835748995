import styled from "styled-components";
import media from "styled-media-query";

export const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  ${media.greaterThan("medium")`
    justify-content: space-between;
  `}
`;

export const Heading = styled.h2`
  text-transform: uppercase;
  font-family: "Caveat Brush", cursive;
  margin: 0;
`;

export const Image = styled.img`
  max-height: 13rem;
  display: none;

  ${media.greaterThan("medium")`
    display: block;
  `}
`;
