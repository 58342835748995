import Header from "components/Header/Header";
import React from "react";
import { animateScroll } from "react-scroll";

import * as Style from "./CaseStudy.style";
import CaseStudySection from "./CaseStudySection/CaseStudySection";

interface Details {
  title: string;
  description: string;
}

const CaseStudy: React.FC<{
  themeBackgroundColor: string;
  themeTextColor: string;
  title: string;
  description: string;
  overview: string;
  details?: Details[];
  children?: React.ReactNode;
}> = (props) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };
  React.useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  return (
    <Style.Wrapper>
      <Header
        backgroundColor={
          scrollPosition > 300 ? "#fff" : props.themeBackgroundColor
        }
        textColor={scrollPosition > 300 ? "#111" : props.themeTextColor}
        shadow={scrollPosition > 300}
        links={[
          {
            to: "/",
            label: "< Tillbaka",
          },
        ]}
        align="flex-start"
        height="small"
      />
      <Style.Intro
        backgroundColor={props.themeBackgroundColor}
        textColor={props.themeTextColor}
      >
        <p>CASE STUDY:</p>
        <h1>{props.title}</h1>
        <p>{props.description}</p>
      </Style.Intro>
      <Style.DetailsSection backgroundColor="#eee">
        <div className="row">
          <div className="column column-75">
            <h6>ÖVERSIKT</h6>
            <p>{props.overview}</p>
          </div>
          <div className="column">
            <h6>DETALJER</h6>
            {props.details &&
              props.details.map((detail, index) => (
                <div key={index}>
                  <strong>{detail.title}:</strong> {detail.description}
                </div>
              ))}
          </div>
        </div>
      </Style.DetailsSection>
      {props.children}
      <CaseStudySection backgroundColor="#eee">
        <Style.Button onClick={scrollToTop}>Tillbaka till toppen</Style.Button>
      </CaseStudySection>
    </Style.Wrapper>
  );
};

export default CaseStudy;
