import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.section<{
  backgroundColor?: string;
  textColor?: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  color: ${({ textColor }) => textColor || "#111"};

  .column {
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
    margin-bottom: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
  }

  ${media.greaterThan("medium")`
    .column {
      margin-bottom: 0rem;
    }
  `}
`;

export const Content = styled.div`
  padding: 2rem;
  max-width: 50rem;
  margin: 0 auto;

  ${media.greaterThan("medium")`
    padding: 4rem 2rem;
  `}
`;

export const Image = styled.img`
  margin-bottom: 2rem;
`;

export const Heading = styled.h6`
  text-transform: uppercase;
`;
