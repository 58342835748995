import React from "react";
import adaCase from "img/ada-case.png";
import process from "img/process.svg";
import design from "img/design.svg";
import personas from "./img/personas.png";
import empathyMapping from "./img/empathy-mapping.png";
import ideation from "./img/ideation.png";
import sitemap from "./img/sitemap.png";
import wireframe1 from "./img/wireframe01.png";
import wireframe2 from "./img/wireframe02.png";
import wireframe3 from "./img/wireframe03.png";
import wireframe4 from "./img/wireframe04.png";
import info1 from "./img/info01.png";
import info2 from "./img/info02.png";
import info3 from "./img/info03.png";
import CaseStudy from "components/CaseStudy/CaseStudy";
import CaseStudySection from "components/CaseStudy/CaseStudySection/CaseStudySection";
import PrototypeDisplay from "components/CaseStudy/PrototypeDisplay/PrototypeDisplay";
import SectionHeader from "components/CaseStudy/SectionHeader/SectionHeader";
import Gallery from "components/CaseStudy/Gallery/Gallery";
import Emphasis from "components/Emphasis/Emphasis";

const caseBgColor = "#fa4888";
const caseTextColor = "#ffffff";

const Ada: React.FC = () => {
  return (
    <CaseStudy
      title="ADA"
      description="En svensk mens-app med fokus på menscykeln kopplat till det psykiska
    måendet."
      themeBackgroundColor={caseBgColor}
      themeTextColor={caseTextColor}
      overview="Under den inledande kursen (interaktionsdesign) på Stockholms
      Tekniska Institut fick vi i uppgift att efter eget intresse hitta på
      en tjänst eller produkt med ett visuellt gränssnitt. I vår
      projektgrupp såg vi en brist på svenska mens/cykelappar som har ett
      fokus på menscykeln kopplat till det psykiska mående."
      details={[
        {
          title: "Roll",
          description: "UX/UI Designer",
        },
        {
          title: "Tidsperiod",
          description: "14 veckor",
        },
        {
          title: "Verktyg",
          description: "Figma, Miro",
        },
        {
          title: "Team",
          description: "3st studerande UX-designers",
        },
      ]}
    >
      <CaseStudySection
        heading="Problemställning"
        texts={[
          <ul>
            <li>Brist på kunskap om menscykeln</li>
            <li>
              Saknas förståelse för måendet i samband med cykelns faser. (Många
              mår dåligt, men förstår inte varför)
            </li>
            <li>
              Svenska marknadens utbud av cykelappar är nästintill obefintligt
            </li>
          </ul>,
        ]}
      />

      <CaseStudySection
        heading="Lösning"
        texts={[
          <ul>
            <li>
              Vi vill hjälpa våra användare med att känna sig förstådda och
              sedda.
            </li>
            <li>
              Vår användare kommer att ha möjlighet till att dela utvalt
              innehåll till partner/vän/familj för att öka förståelse och empati
              i användarens sociala krets.
            </li>
            <li>
              Det som skiljer oss från redan befintliga applikationer är
              tydlighet (Svenska), enkelhet, empati och effektivt visad
              förståelse mellan cykeln och mående.
            </li>
          </ul>,
        ]}
      />

      <PrototypeDisplay
        image={adaCase}
        link="https://www.figma.com/proto/XQefzPOT2Cm5hM3mJz2N3H/Ada?kind=&node-id=1149-3111&starting-point-node-id=1149%3A3111&scaling=scale-down"
        buttonColor={caseBgColor}
      />

      <SectionHeader
        title="Designprocess"
        textColor={caseBgColor}
        backgroundColor="#fff"
        image={process}
      />

      <CaseStudySection
        heading="Intervjuer"
        texts={[
          <p>
            Vi intervjuade 6 personer som passade in i målgruppen och försökte
            hitta och förstå deras behov samt utmaningar kring sin menscykel
            kopplat till deras mående.
          </p>,
          <>
            <ul style={{ marginBottom: "2rem" }}>
              <li>6 st kvinnor, 31-44 år</li>
              <li>Samtliga är fysiskt aktiva i vardagen</li>
              <li>
                Registrerar sin cykel på något sätt (av olika anledningar)
              </li>
              Påverkas mentalt och/eller psykiskt av sin cykel
              <li>Brist på kunskap om koppling mellan cykel och mående</li>
              <li>Varierande matintresse</li>
              <li>
                Varierande intresse för kopplingen mellan cykel och mående
              </li>
            </ul>
            <strong>Användaren vill känna sig:</strong>
            <br />
            Medveten, sedd, hörd, tillräcklig, uppmuntrad
          </>,
        ]}
      />

      <CaseStudySection
        heading="Persona"
        image={personas}
        texts={[
          <p>
            Resultaten från intervjuerna lade grunden till vår{" "}
            <Emphasis color={caseBgColor}>persona</Emphasis> och på så vis
            förstå användarens behov, beteenden, utmaningar och mål bättre.
          </p>,
          <>
            <p>
              <strong>Behov:</strong>
              <br />
              Okomplicerat, registrera menscykel, planering av mat
            </p>
            <p>
              <strong>Krav:</strong>
              <br />
              Tidseffektiva medel, Lättanvänd
            </p>
            <p>
              <strong>Förutsättningar:</strong>
              <br />
              - Intresserad av att lära känna sin kropp bättre utifrån sin cykel
              <br />
              - Inte främmande att använda appar eller liknande hjälpmedel
              <br />
              - Mån om sin hälsa
              <br />- Planerande
            </p>
          </>,
        ]}
      />

      <CaseStudySection
        heading="Empathy Mapping"
        image={empathyMapping}
        texts={[
          <p>
            Vi använde oss av{" "}
            <Emphasis color={caseBgColor}>Empathy Mapping</Emphasis> som ett
            komplement för att djupare förstå och relatera till vår användare
            ännu bättre.
          </p>,
        ]}
      />

      <CaseStudySection
        heading="Tone of Voice"
        texts={[
          <ul>
            <li>Bekräfta och uppmuntra</li>
            <li>
              Kommunicera med användaren på ett mjukt och upplyftande sätt
            </li>
            <li>
              Visa förståelse och ge en känsla av ödmjukhet och bekräftelse
            </li>
            <li>Vara empatisk och respektfull</li>
          </ul>,
          <p>
            <strong>Motivation:</strong>
            <br />
            Vi ser ett behov av att användare ska känna sig/må bättre och att
            man duger under dagar man mår dåligt. Användaren ska känna sig{" "}
            <Emphasis color={caseBgColor}>sedd</Emphasis> och{" "}
            <Emphasis color={caseBgColor}>hörd</Emphasis> och därav få{" "}
            <Emphasis color={caseBgColor}>bekräftelse</Emphasis> på sina
            känslor. Vi ser ett behov av att känna sig tillräcklig och icke
            dömd, att mötas av en{" "}
            <Emphasis color={caseBgColor}>uppmuntrande ton</Emphasis>.
          </p>,
        ]}
      />

      <CaseStudySection
        heading="Ideation"
        image={ideation}
        texts={[
          <p>
            <strong>Process:</strong>
            <br />
            Under ideation-processen använde vi oss av{" "}
            <Emphasis color={caseBgColor}>brainstorming</Emphasis>. Genom denna
            metod fick vi en bra överblick över vad vi behövde i appen utöver
            det vanliga och vilka idéer som var mest relevanta.
          </p>,
          <p>
            <strong>Resultat:</strong>
            <br />
            Det ska vara möjligt att{" "}
            <Emphasis color={caseBgColor}>dela information</Emphasis> om sitt
            dagliga mående i appen till partner/familj/vänner för att skapa{" "}
            <Emphasis color={caseBgColor}>förståelse</Emphasis> till sin
            omgivning.
          </p>,
        ]}
      />

      <SectionHeader
        title="Sitemap, Wireframes & Prototyp"
        textColor="#fa4888"
        backgroundColor="#fff"
        image={design}
      />

      <CaseStudySection image={sitemap} />

      <Gallery
        backgroundColor="#fff"
        images={[wireframe1, wireframe2, wireframe3, wireframe4]}
      />

      <Gallery backgroundColor="#fff" images={[info1, info2, info3]} />

      <PrototypeDisplay
        backgroundColor="#fff"
        link="https://www.figma.com/proto/XQefzPOT2Cm5hM3mJz2N3H/Ada?kind=&node-id=1149-3111&starting-point-node-id=1149%3A3111&scaling=scale-down"
        buttonColor="#fa4888"
      />

      <CaseStudySection
        backgroundColor="#eee"
        heading="Slutsats"
        texts={[
          <p>
            Efterson att detta var ett skolprojekt med ett slutdatum har
            ytterligare användarbarhetstester dessvärre inte utförts, vilket
            hade varit ett intressant och spännande segment för slutprodukten.
          </p>,
          <p>
            <strong>De främsta insikterna vi kom fram till var:</strong>
            <br />- Tyngden i att{" "}
            <Emphasis color={caseBgColor}>tydliggöra</Emphasis> och{" "}
            <Emphasis color={caseBgColor}>välarbeta</Emphasis> frågeställningen
            inför intervjuer
            <br />- Vikten av att göra{" "}
            <Emphasis color={caseBgColor}>kontinuerliga</Emphasis>{" "}
            användbarhetstester och observationer
          </p>,
        ]}
      />
    </CaseStudy>
  );
};
export default Ada;
