import React from "react";

import * as Style from "./CaseStudySection.style";

const CaseStudySection: React.FC<{
  backgroundColor?: string;
  textColor?: string;
  heading?: string;
  texts?: React.ReactNode[];
  image?: string;
  children?: React.ReactNode;
}> = (props) => {
  return (
    <Style.Wrapper
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
    >
      <Style.Content>
        {props.image && (
          <Style.Image src={props.image} alt={props.heading || "case study"} />
        )}

        {props.heading &&
          (!props.texts || (props.texts && props.texts.length > 1)) && (
            <Style.Heading>{props.heading}</Style.Heading>
          )}

        {props.texts && props.texts.length > 0 && (
          <div className="row">
            {props.heading && props.texts && props.texts.length === 1 && (
              <div className="column">
                <Style.Heading>{props.heading}</Style.Heading>
              </div>
            )}

            {props.texts &&
              props.texts.map((text, index) => (
                <div className="column" key={index}>
                  {text}
                </div>
              ))}
          </div>
        )}
        {props.children}
      </Style.Content>
    </Style.Wrapper>
  );
};

export default CaseStudySection;
