import React from "react";
import CaseStudySection from "../CaseStudySection/CaseStudySection";

import * as Style from "./SectionHeader.style";

const SectionHeader: React.FC<{
  backgroundColor?: string;
  textColor?: string;
  title: string;
  image?: string;
}> = (props) => {
  return (
    <CaseStudySection
      backgroundColor={props.backgroundColor || "#eee"}
      textColor={props.textColor}
    >
      <Style.Inner>
        <Style.Heading>{props.title}</Style.Heading>
        {props.image && (
          <Style.Image
            src={props.image}
            alt={props.title || "section header"}
          />
        )}
      </Style.Inner>
    </CaseStudySection>
  );
};

export default SectionHeader;
