import styled from "styled-components";

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CTAButton = styled.a<{ buttonColor?: string }>`
  background-color: ${({ buttonColor }) => buttonColor || "#222222"};
  border: 0.1rem solid ${({ buttonColor }) => buttonColor || "#222222"};
  border-radius: 0.4rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: 0.1rem;
  line-height: 3.8rem;
  padding: 0 3rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    border-color: #fff;
    background-color: #fff;
    color: ${({ buttonColor }) => buttonColor || "#222222"};
  }
`;

export const Image = styled.img`
  max-height: 25rem;
  margin-bottom: 2rem;
`;
