import React from "react";
import * as Style from "./Case.style";

const Case: React.FC<{
  backgroundColor?: string;
  textColor?: string;
  title: string;
  description: string;
  imageSrc: string;
  link: string;
}> = (props) => {
  return (
    <Style.Container
      backgroundColor={props.backgroundColor || "#222"}
      textColor={props.textColor || "#fff"}
      href={props.link}
    >
      <Style.CaseInfo>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
        <Style.CTAButton>Läs mer</Style.CTAButton>
      </Style.CaseInfo>
      <Style.ImageContainer>
        <img src={props.imageSrc} alt={`${props.title} case`} />
      </Style.ImageContainer>
    </Style.Container>
  );
};

export default Case;
