import styled from "styled-components";
import media from "styled-media-query";
import { Link as ScrollLink } from "react-scroll";

export type Height = "small" | "medium" | "large";
export type Align = "flex-start" | "flex-end" | "center" | "space-between";

const heightToPadding = (height?: Height) => {
  switch (height) {
    case "small":
      return "1rem 0";
    case "medium":
      return "2rem 0";
    case "large":
      return "3rem 0";
    default:
      return "2rem 0";
  }
};

export const HeaderContainer = styled.header<{
  backgroundColor?: string;
  align?: Align;
  height?: Height;
  shadow?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ height }) => heightToPadding(height)}};
  transition: all 0.1s;
  position: sticky;
  top: 0;
  background: ${({ backgroundColor }) => backgroundColor || "##ffffff"}};
  z-index: 10;
  transition: all 0.25s;

  ${({ shadow }) => shadow && `box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);`}

  ${media.greaterThan<{ align?: Align }>("medium")`
    justify-content: ${({ align }) => align || "flex-end"}};
  `}
`;

const LinkStyle = ({ textColor }: { textColor?: string }) => `
  color: ${textColor || "#222"};
  font-size: 1rem;
  padding: 0.25rem 0.5rem; 
  margin: 0 1rem;
  transition: all 0.1s;
  text-transform: uppercase;
  position: relative;
  bottom: 0px;
  text-decoration: underline;
  transform: scale(1);

  &:hover {
    transform: scale(1.1);
    background: transparent;
    color: ${textColor || "#222"};
    text-decoration: none;
    cursor: pointer;
  }

  &.active {
    transform: scale(1.1);
    background: transparent;
    color: ${textColor || "#222"};
    font-weight: 900;
    text-decoration: none;
  }
`;

export const AnchorLink = styled(ScrollLink)<{ textColor?: string }>`${({
  textColor,
}) => LinkStyle({ textColor })}}`;

export const Link = styled.a<{ textColor?: string }>`${({ textColor }) =>
  LinkStyle({ textColor })}}`;
