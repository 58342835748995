import React from "react";
import CaseStudySection from "../CaseStudySection/CaseStudySection";

import * as Style from "./PrototypeDisplay.style";

export interface PrototypeDetails {
  link?: string;
  image?: string;
  backgroundColor?: string;
  buttonColor?: string;
}

const PrototypeDisplay: React.FC<
  {
    children?: React.ReactNode;
  } & PrototypeDetails
> = (props) => {
  return (
    <CaseStudySection backgroundColor={props.backgroundColor || "#eee"}>
      <Style.Inner>
        {props.image && <Style.Image src={props.image} alt="Prototyp" />}
        {props.link && (
          <Style.CTAButton
            target="_blank"
            href={props.link}
            buttonColor={props.buttonColor}
          >
            Utforska Prototypen
          </Style.CTAButton>
        )}

        {props.children}
      </Style.Inner>
    </CaseStudySection>
  );
};

export default PrototypeDisplay;
