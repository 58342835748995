import React from "react";
import Header from "components/Header/Header";
import adaCase from "img/ada-case.png";
import emailImg from "img/email.svg";
import portraitImg from "img/emmilie-portrait.jpeg";
import everydayImg from "img/everyday.png";
import linkedinImg from "img/linkedin.svg";
import topImg from "img/palette.svg";
import * as Style from "./Home.style";
import Case from "./Case/Case";
import Emphasis from "components/Emphasis/Emphasis";

const Home: React.FC = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };
  React.useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Header shadow={scrollPosition > 50} />
      <Style.Wrapper>
        <Style.Section id="home">
          <Style.DesignImage src={topImg} alt="light bulb" />
          <Style.Title>EMMILIE ALMLÖF</Style.Title>
          <Style.Subtitle>UX-DESIGNER</Style.Subtitle>

          <Style.Intro>
            Tidigare bildlärare som just nu studerar en 2-årig utbildning till
            UX&#x2011;designer på Stockholms Tekniska Institut.
          </Style.Intro>

          <Style.RibbonContainer>
            <Style.Ribbon>
              <Style.RibbonContent>
                Söker praktikplats (LIA) HT 2023 v.40-47
              </Style.RibbonContent>
            </Style.Ribbon>
          </Style.RibbonContainer>
        </Style.Section>

        <Style.Cases id="portfolio">
          <Style.SectionHeading>PORTFOLIO</Style.SectionHeading>
          <Case
            title="ADA"
            description="En svensk mens-app med fokus på menscykeln kopplat till det psykiska måendet."
            imageSrc={adaCase}
            link="/ada"
            backgroundColor="#fa4888"
            textColor="#ffffff"
          />
        </Style.Cases>

        <Style.Section id="about">
          <Style.SectionHeading>OM MIG</Style.SectionHeading>
          <Style.AboutSection>
            <div style={{ width: "100%" }}>
              <Style.Paragraph>
                <strong>
                  Utbildad bildlärare som nu skolar om mig till{" "}
                  <Emphasis color={Style.themeColor}>UX:are</Emphasis>.
                  <br />
                  Det är <Emphasis color={Style.themeColor}>
                    spännande
                  </Emphasis>{" "}
                  att prova på nya saker i livet!
                </strong>
              </Style.Paragraph>
              <Style.Paragraph>
                Jag tycker om att arbeta effektivt och varierande och ser mig
                gärna som en del i en grupp för att både ge och få inspiration.
              </Style.Paragraph>
              <Style.Paragraph>
                Att skapa bra användarupplevelser genom komma användarna nära
                och verkligen förstå deras beteende och drivkrafter är för mig
                jätteviktigt.
              </Style.Paragraph>
              <Style.Paragraph>
                Jag är en social person som gärna träffar mina närmaste så ofta
                jag kan. Det är kul att få vara kreativ och se nya platser. Mitt
                yrväder till dotter på 1,5 år är den bästa personen jag vet.
              </Style.Paragraph>
              <Style.Paragraph>
                Sommar, yoga, djur och godis gör livet lite bättre tycker jag!
                🤗
              </Style.Paragraph>
              <Style.Paragraph style={{ margin: 0 }}>
                Jag söker just nu praktikplats (LIA) under höstterminen 2023
                veckorna 40-47.{" "}
                <Emphasis color={Style.themeColor}>Hör gärna av dig!</Emphasis>
              </Style.Paragraph>
            </div>
            <Style.AboutVisuals>
              <Style.Portrait src={portraitImg} alt="emmilie almlöf portrait" />
              <Style.SocialLinks>
                <a href="https://www.linkedin.com/in/emmilie-alml%C3%B6f-87831a181/">
                  <img src={linkedinImg} alt="LinkedIn" />
                </a>
                <a href="mailto:emmilie.almlof@gmail.com">
                  <img src={emailImg} alt="Email" />
                </a>
              </Style.SocialLinks>
            </Style.AboutVisuals>
          </Style.AboutSection>
          <img src={everydayImg} alt="everyday" />
        </Style.Section>
        <Style.Section>
          <img src={emailImg} alt="Email" style={{ marginBottom: "1rem" }} />{" "}
          emmilie.almlof@gmail.com
        </Style.Section>
      </Style.Wrapper>
    </>
  );
};

export default Home;
