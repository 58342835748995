import React from "react";
import styled from "styled-components";

const Container = styled.span<{ color?: string }>`
  font-family: "Caveat Brush", cursive;
  color: ${(props) => props.color || "#111"};
  text-transform: uppercase;
  font-weight: 100;
`;

const Emphasis: React.FC<{
  color?: string;
  children: React.ReactNode;
}> = (props) => {
  return <Container color={props.color}>{props.children}</Container>;
};

export default Emphasis;
