import React from "react";
import * as HeaderStyle from "./Header.style";

interface LinkProps {
  to: string;
  label: string;
}

const Header: React.FC<{
  ref?: React.RefObject<HTMLDivElement>;
  backgroundColor?: string;
  textColor?: string;
  links?: LinkProps[];
  align?: HeaderStyle.Align;
  height?: HeaderStyle.Height;
  shadow?: boolean;
}> = (props) => {
  return (
    <HeaderStyle.HeaderContainer
      backgroundColor={props.backgroundColor || "#ffffff"}
      align={props.align}
      height={props.height}
      ref={props.ref}
      shadow={props.shadow}
    >
      {!props.links && (
        <>
          <HeaderStyle.AnchorLink
            smooth
            spy
            to="home"
            offset={-100}
            textColor={props.textColor}
          >
            Start
          </HeaderStyle.AnchorLink>
          <HeaderStyle.AnchorLink
            smooth
            spy
            to="portfolio"
            offset={-100}
            textColor={props.textColor}
          >
            Portfolio
          </HeaderStyle.AnchorLink>
          <HeaderStyle.AnchorLink
            smooth
            spy
            to="about"
            offset={-100}
            textColor={props.textColor}
          >
            Om Mig
          </HeaderStyle.AnchorLink>
        </>
      )}
      {props.links?.map((link) => (
        <HeaderStyle.Link
          key={`header-link-${link.to}`}
          href={link.to}
          textColor={props.textColor}
        >
          {link.label}
        </HeaderStyle.Link>
      ))}
    </HeaderStyle.HeaderContainer>
  );
};

export default Header;
