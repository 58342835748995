import React, { PropsWithChildren } from "react";
import * as AppStyle from "./App.style";
import { Outlet } from "react-router-dom";

const Layout: React.FC<PropsWithChildren> = (props) => {
  return (
    <>
      <AppStyle.GlobalStyle />
      <AppStyle.Content>
        <Outlet />
      </AppStyle.Content>
    </>
  );
};

export default Layout;
