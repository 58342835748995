import styled from "styled-components";
import media from "styled-media-query";

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  div {
    width: 50%;
  }

  img {
    margin: 1rem;
    max-width: 100%;
  }

  ${media.greaterThan("medium")`
    flex-direction: row;
  `}
`;
