import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #444444;
    background-color: #fefefe;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 900;
  }
  @keyframes text-focus-in {
    0% {
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      filter: blur(0px);
      opacity: 1;
    }
  }

`;

export const Content = styled.div``;
