import styled from "styled-components";
import media from "styled-media-query";

export const themeColor = "#f5b144";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;

  ${media.greaterThan("small")`
    margin-top: 0rem;
  `}
`;

export const Title = styled.h1`
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-size: 4rem;
  font-family: "Caveat Brush", cursive;
  text-align: center;
`;

export const Subtitle = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
`;

export const Intro = styled.p`
  font-weight: 100;
  margin-bottom: 0.5rem;
  max-width: 30rem;
  text-align: center;
`;

export const SectionHeading = styled.h3`
  text-align: center;
  position: relative;
  z-index: 1;
  color: #777777;
  margin-bottom: 3rem;
`;

export const Section = styled.section`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  ${media.greaterThan("medium")`
    margin-bottom: 4rem;
  `}
`;

export const Paragraph = styled.p`
  font-weight: 100;
  margin-bottom: 1rem;
`;

export const RibbonContainer = styled.div`
  position: relative;
  z-index: 1;
  margin: 2rem;
`;

export const Ribbon = styled.div`
  font-size: 16px !important;
  position: relative;
  background: ${themeColor};
  color: #444444;
  text-align: center;
  padding: 0.5em 1.5em;

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: -1em;
    border: 1.5em solid #dda246;
    z-index: -1;
  }

  &:before {
    left: -2em;
    border-right-width: 1.5em;
    border-left-color: transparent;
  }

  &:after {
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent;
  }
`;

export const RibbonContent = styled.span`
  font-size: 1rem;
  font-weight: 900;

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    border-style: solid;
    border-color: #b37d28 transparent transparent transparent;
    bottom: -1em;
  }

  &:before {
    left: 0;
    border-width: 1em 0 0 1em;
  }

  &:after {
    right: 0;
    border-width: 1em 1em 0 0;
  }
`;

export const Cases = styled.section`
  position: relative;
  margin-bottom: 2rem;
`;

export const AboutSection = styled.section`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 4rem;
  align-items: center;

  ${media.greaterThan("medium")`
    flex-direction: row;
    align-items: flex-start;
  `}
`;

export const AboutVisuals = styled.div`
  margin-left: 0;
  margin-bottom: 2rem;
  ${media.greaterThan("medium")`
    width: 25rem;
    margin-left: 2rem;
    margin-bottom: 0rem;
  `}
`;

export const Portrait = styled.img`
  border-radius: 3rem;

  ${media.greaterThan("medium")`
    margin-bottom: 0;
  `}
`;

export const DesignImage = styled.img`
  width: 13rem;
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  a {
    transition: all 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }

  img {
    margin: 0 1rem;
    height: 3.5rem;
  }
`;
