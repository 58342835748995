import styled from "styled-components";
import media from "styled-media-query";
import CaseStudySection from "./CaseStudySection/CaseStudySection";

export const Wrapper = styled.div``;

export const Intro = styled.section<{
  backgroundColor: string;
  textColor: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0 4rem 2rem 4rem;
  color: ${({ textColor }) => textColor}};
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    margin: 0;
    text-align: center;
  }

  h1 {
    margin-bottom: 0rem;
    animation-name: text-focus-in;
    animation-duration: 1s;
  }
`;

export const DetailsSection = styled(CaseStudySection)`
  padding-top: 2rem;
  ${media.greaterThan("medium")`
    padding-top: 3rem;
  `}
`;

const buttonStyle = `
  background-color: #ffffff;
  border: 0.1rem solid #222222;
  border-radius: 0.4rem;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: 0.1rem;
  line-height: 3.8rem;
  padding: 0 3rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;

  &:hover {
    border-color: #fff;
    background-color: #222222;
    color: #ffffff;
  }
`;

export const ButtonLink = styled.a`
  ${buttonStyle}
`;

export const Button = styled.button`
  ${buttonStyle}
`;
